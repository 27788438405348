import { Button } from 'antd';
import { Card } from 'antd';
import styled from '@emotion/styled';

const ExportStyled = styled.div`
`
function Export() {

    return <ExportStyled>  <Card title="Export" extra={<Button>zatwierdz</Button>} style={{ width: 300 }}>
    <p>Export bazy do zewnęrznej bazy danych</p>
  </Card></ExportStyled>

}

export default Export;