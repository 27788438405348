import { Button, Checkbox, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { user$ } from "./../../providers/data";
import { httpClient } from '../../providers/httpClient';
import { catchError, of, Subject } from 'rxjs';
import {exhaustMap, tap} from "rxjs/operators"
import { Alert, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';

const request$ = new Subject();
const LogIn = () => {
    const navigate = useNavigate();
    const [formStatus, setFormStatus] = useState();
    const onFinish = (values) => {
        console.log("finish");
        request$.next(values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        user$.next({});

        console.log("effects");


        const requestSubscribe = request$.pipe(tap((co)=>console.log("co",co)),exhaustMap((data) => {
            console.log("login test")
            setFormStatus("checking")
            return httpClient.logIn(data).pipe(catchError((err) => {
                console.log("err", err);
                return of(null);
            }));
        })).subscribe((data) => {
            const user = JSON.parse(data);
            if (data) {
                user$.next(user);
                navigate("./../database");
            } else {
                setFormStatus("error");
            }
        })

        return ()=>requestSubscribe.unsubscribe();

    }, []);

    return (
        <div style={{ padding: "3rem" }}>

            {formStatus === "error" && <Alert style={{marginBottom: "1rem"}} message="Bad username or password" type="error" showIcon />}
            {formStatus !== "checking" && <Form
                name="basic"
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 12,
                }}
                initialValues={{
                    remember: true,
                }}
                onChange={() => setFormStatus(null)}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="remember"
                    valuePropName="checked"
                    wrapperCol={{
                        offset: 6,
                        span: 12,
                    }}
                >
                    <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 6,
                        span: 12,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Zatwierdz
                    </Button>
                </Form.Item>
            </Form>}
            {formStatus === "checking" && <Spin style={{ textAligin: 'left', margin: '2rem' }} size="large" />}
        </div>
    );
};
export default LogIn;