const addedStyles = new Map();
export const addStyle = function(style) {
   // const styleId = Math.random() * 1000 +'_'+ new Date().getTime();
    if(addedStyles.has(style)){
        if(!this.styleId){
            this.styleId = Math.random() * 1000 +'_'+ new Date().getTime();
            addedStyles.get(style).ids.add(this.ids);
        }

    } else {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = style;
    document.body.appendChild(styleElement);
    this.styleId = Math.random() * 1000 +'_'+ new Date().getTime();
    styleElement.id = this.styleId;
    addedStyles.set(style,{ids:new Set([this.styleId]), styleElement })
    }
  
    const oldUnmount = this.componentWillUnmount;
    this.componentWillUnmount = ()=>{
        this.styleId = null;
        removeStyle(this.styleId, style);
        try {
            oldUnmount();
        } catch(e){
            console.warn('err',e)
        }
    }

}

function removeStyle(styleId, style) {          
    
    const addedStyle = addedStyles.get(style);
    const stylesIds = addedStyle.ids;
    stylesIds.delete(styleId);    
    if(!stylesIds.size){
        addedStyle.styleElement.remove();
        addedStyles.delete(style);
    }

}