import { Menu } from 'antd';
import { utils, writeFile } from "xlsx";
export function DownLoadMenu({ data }) {

  const onClick = ({ key }) => {
    if (key.substr(0, 5) === "image") {
      console.log("key", key);
      saveImage(key);
    } else {
      saveExel(data);
    }
  }


  return <><Menu onClick={onClick}
    items={[
      {
        key: 'image/png',
        label: "obraz png"
      },
      {
        key: 'exel',
        label: 'plik exel',
      },
    ]}
  /><a id="link" style={{ opacity: 0 }}></a></>

}

const saveImage = (type = "image/png") => {
  const canvas = document.querySelector("canvas");
  let link = document.getElementById('link');
  const end = type.split("/")[1];
  link.setAttribute('download', 'stats.' + end);
  link.setAttribute('href', canvas.toDataURL(type));
  link.click();
}

const saveExel = ({ data, name, values, short }) => {
  const rows = [...data];
  const worksheet = utils.json_to_sheet(rows);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, short);
  utils.sheet_add_aoa(worksheet, [[name, values]], { origin: "A1" });

  /* calculate column width */
  const max_width = rows.reduce((w, r) => Math.max(w, r[0].length), 10);
  worksheet["!cols"] = [{ wch: max_width },{ wch: values.length }];

  /* create an XLSX file and try to save to Presidents.xlsx */
  writeFile(workbook, "Statystyki.xlsx");

}