import { Menu } from 'antd';
import styled from '@emotion/styled';
import {
     useNavigate
} from 'react-router-dom';

import { useEffect } from "react";

const titles = [
    ["Statystyki ogólne prezentacji", [
        { title: "Liczba wyświetleń", num: 1 },
        { title: "Czasy wyświetleń", num: 2 }]
    ],
    ["Statystyki ogólne slajdów", [
        { title: "Liczba wyświetleń", num: 3 },
        { title: "Czasy wyświetleń", num: 4 }]
    ],
    ["Statystyki ogólne przedstawicieli", [
        { title: "Liczba wyświtleń wizyt", num: 5 },
        { title: "Całkowity czas wizyt", num: 6 },
        { title: "Całkowity czas wizyt wraz z wygaszeniem urządzenia", num: 7 }]
    ]
];

const items = titles.map(([key, vals], index)=>{
    return {
        label: key,
        index,
        children: vals.map(val => ({label:val.title, key: val.num}))
      }
})

items.unshift( { label: 'Export', key: 0 })

const TopNavigationStyled = styled.header`

--ant-primary-color:#149288;

background-color: #fafafa;
background-image: linear-gradient(to bottom, #ffffff, #f2f2f2);
background-repeat: repeat-x;
border: 1px solid #d4d4d4;
display: flex;
align-items: center;
img {
    vertical-align: middle;
    max-width: none;
    object-fit: scale-down;
    width: 240px;
    height: 4rem;
    padding: 0.5rem;
    opacity: 0;
    
}
.show {
    opacity: 1;
    transition: opacity 1s ease; 
    transition-delay: 0.5s;

}

nav{
    margin-left: 1rem;
    width: 100%;
   & > ul {
    background-color:inherit;
   } 

   .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover {
    color: #149288;
}



 

  

   .ant-menu-light .ant-menu-submenu-title:hover{
    color: #149288;
   }

   .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover:after {
    border-bottom: 2px solid #149288;
}

   .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover,.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active,
   .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active {
    color: #149288;
    & :after{
        border-bottom-color: #149288;

    }
    .ant-menu-submenu-title::after{
        border-bottom: 2px solid #149288;

    }
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open:after{
    border-bottom-color: #149288;
}

   .ant-menu-item-selected {
     color: #149288 !important;
    .ant-menu-title-content{
        color: #149288;
    }
  }
}

`

function TopNavigation({user}) {

    const navigate = useNavigate();

    useEffect(()=>{
        if(!user) {
         navigate("login");
        }
       },[user])
    const onClick = e => {
        console.log('click ', e);
        if(e.key === "0"){
            navigate("export");
        } else {
            navigate("stats/chart/" + e.key);
        }
      };

    return <TopNavigationStyled><img className="show logo" src="skyrep_logo.png" />{user && <nav><Menu mode="horizontal" onClick={onClick} items={items}></Menu></nav>}</TopNavigationStyled>
}

export default TopNavigation;