import { useEffect, memo, useState, useRef } from "react";
import Chart from "./chart";
import { DownLoadMenu } from "./downloadMenu";
import { DatePicker, Button, Dropdown } from 'antd';
import {
    CloudDownloadOutlined,
} from '@ant-design/icons';
import * as moment from 'moment';
import styled from '@emotion/styled';
import { PageHeader } from 'antd';
import {
    // rest of the elements/components imported remain same
    useLocation, useNavigate
} from 'react-router-dom';
import { przeliczenia } from "../../../providers/przeliczenia.js";

const { RangePicker } = DatePicker;
const MAX_ITEMS = 200;
let start_date_range = [];


const ChartPageStyled = styled.div`
padding: 1rem;
.site-page-header {
    border: 1px solid rgb(235, 237, 240);
    margin-bottom: 2rem;
    padding: 0.5rem 2rem;
  }
  .download{
    float: right;
    margin-right: 3.2em;
  }
`

export const ChartPage = memo(({ baseData, num, onHideLoader, waitUntilCount, dirs }) => {
    console.log("znowu chart page");
    const data = useRef();
    const { state } = useLocation();
    const group = state?.group;
    const navigate = useNavigate();

    const [dateRangeState, setDateRangeState] = useState();
    const [allData, setAllData] = useState();

    const calendarChange = (event) => {
        console.log("calendar czange");
        setDateRangeState(event || start_date_range)
    };

    const goBack = () => {
        navigate("../../groups");
    };

    const [defaultCalendarData, setDefaultCalendarData] = useState([przeliczenia.defaultStartData, moment()]);




    useEffect(() => {

        if(waitUntilCount) {

        console.log("use nemo")
        console.log("num", num);

        const dateRange = dateRangeState || defaultCalendarData;
        const minMax = dateRange.map((val) => val ? val.valueOf() : 0);
        console.log("minMax", minMax);
        if (num === 1) {
            const data = Array.from(przeliczenia.przeliczPrezentacjeWlaczenia(baseData, minMax));
           setAllData({ data, name: "prezentacje", values: "liczba włączeń", short: "Statystyki prezentacji" });
        }
        else if (num === 2) {
            const data = Array.from(przeliczenia.przeliczPrezentacjeCzasy(baseData, minMax));
            setAllData({ data, title: "Całkowity czas wyświetlania Prezentacji.", unit: "spędzony czas", time: true, name: "prezentacje", values: "czas w milisekundach", short: "Czas wyświetlania Prezentacji" });
        }
        else if (num === 3) {
            const [data, prezentacjeNazwy] = Array.from(przeliczenia.przeliczSlajdyWlaczenia(baseData, minMax));
            setAllData({ data, prezentacjeNazwy, title: "Ilość wyświetleń Slajdów.", unit: "ilość wyświetleń", name: "slajdy", values: "wyświetlenia", short: "Wyświetlania slajdów" });
        }
        else if (num === 4) {
            const [data, prezentacjeNazwy] = Array.from(przeliczenia.przeliczSrednieCzasySlajdow(baseData, minMax));
            setAllData({ data, prezentacjeNazwy, title: "Średni czas swyświetlania slajdów.", unit: "czas w sekundach", timeLegend: true, name: "slajdy", values: "czas w milisekundach", short: "Czas wyświetlania slajdów" });
        }
        else if (num === 5) {
            const data = Array.from(przeliczenia.przeliczWlaczeniaWizyt(baseData, minMax, group?.members));
            setAllData({ data, title: "Liczba wyświetleń wszytskich wizyt.", unit: "ilość wizyt", name: "przedstawiciel", values: "wizyty", short: "Statystyki wizyt" });
        }
        else if (num === 6) {
            const data = Array.from(przeliczenia.przeliczCzasyWizyt(baseData, minMax, group?.members));
            setAllData({ data, title: "Całkowity czas odbytych wizyt.", unit: "czas wizyt", time: true, name: "przedstawiciel", values: "czas w milisekundach", short: "Czas wizyt" });
        }
        else {
            const data = Array.from(przeliczenia.przeliczCzasyWizyt(baseData, minMax, group?.members, false));
            setAllData({ data, title: "Całkowity czas odbytych wizyt.(liczony razem z wygaszenim urządzenia)", unit: "czas wizyt", time: true, name: "przedstawiciel", values: "czas w milisekundach", short: "Czas wizyt" });
        }
    }

    }, [waitUntilCount]);

    useEffect(() => {
        setDefaultCalendarData((prev) => {
            start_date_range = [przeliczenia.defaultStartData, prev[1]];
            return start_date_range;
        }
        );
    }, [])

    useEffect(() => {
        console.log("useEffect")
            onHideLoader(false);
    }, [num, dateRangeState]);

    useEffect(() => {
        if(allData){
            onHideLoader(true);
        }
    }, [allData]);


    console.log("renderer chartPage", defaultCalendarData);
    return <ChartPageStyled>
        {group && <PageHeader
            className="site-page-header"
            onBack={goBack}
            title={"Statystyki grupy " + group.name?.toLowerCase()}
            subTitle={"lider :" + group.lider}
        />}
        {defaultCalendarData.every(Boolean) && <RangePicker defaultValue={defaultCalendarData} onCalendarChange={calendarChange} value={dateRangeState} />}
        <Dropdown className="download" overlay={<DownLoadMenu data={allData} />} placement="bottomRight" arrow={{ pointAtCenter: true }}>
            <Button icon={<CloudDownloadOutlined />}>pobierz</Button>
        </Dropdown>
        {!waitUntilCount && <Chart {...allData} dirs={dirs} />}
    </ChartPageStyled>

})