import { Card } from 'antd';
import { Select } from 'antd';
import { useMemo } from 'react';

const options=[
    {
      value: 'slajdy150',
      label: 'aplikacja 5 kafelkowa',
    },
    {
      value: 'slajdy153',
      label: 'aplikacja 6 kafelkowa',
    }, {
      value: 'slajdy51',
      label: 'aplikacja testowa',
    }]


function Database({dataBaseName, onChange, userStatus}) {

 const optionToSelect = useMemo(()=>{
  if(userStatus){
    return options
  }
  return [options[1]];
 },[userStatus]);

    return  <Card title="Wybierz bazę" style={{ width: 300 }}>
    <p>Aktualna baza danych:</p>
    <Select
      value={dataBaseName}
      style={{ width: 220 }}
      options={optionToSelect}
      onChange={onChange}
    />
  </Card>

}

export default Database;