import { Link } from 'react-router-dom';

function LeftNavigation() {
    return <aside>
        <ul className="nav nav-tabs nav-stacked ng-scope">
            <li><Link to="login"><div className="icon-wrapper"><i className="icon-logout"></i></div>Wyloguj</Link></li>
            <li><Link to="setting"><div className="icon-wrapper"><i className="icon-edit"></i></div>Ustawienia konta</Link></li>
            <li><Link to="stats/chart/4"><div className="icon-wrapper"><i className="icon-time"></i></div>Czas wyświetleń slajdów</Link></li>
            <li><Link to="stats/chart/3"><div className="icon-wrapper"><i className="icon-bar-chart"></i></div>Liczba wyświetleń slajdów</Link></li>
            <li><Link to="groups"><div className="icon-wrapper"><i className="icon-group"></i></div>Grupy przedstawicieli</Link></li>
            <li><Link to="stats/week"><div className="icon-wrapper"><i className="icon-table"></i></div>Statystyki szczegółowe</Link></li>
            <li><Link to="database"><div className="icon-wrapper"><i className="icon-database"></i></div>Baza danych</Link></li>
        </ul></aside>;
}
export default LeftNavigation;