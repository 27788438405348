import { Button, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { httpClient } from '../../providers/httpClient';
import { catchError, of, Subject } from 'rxjs';
import { exhaustMap, tap } from "rxjs/operators"
import { Alert, Spin } from 'antd';

const request$ = new Subject();
const Edition = ({user, onChange}) => {
    const [formStatus, setFormStatus] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const onFinish = (values) => {
        console.log("finish");

        request$.next({username:user, ...values});
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {

        console.log("effects");


        const requestSubscribe = request$.pipe(tap((co) => console.log("co", co)), exhaustMap((data) => {
            if(data.password2 !== data.password3){
                return of({err:"hasła sie nie zgadzają!"});
            }
            setFormStatus("checking")
            return httpClient.changeLogIn(data).pipe(catchError((err) => {
                console.log("err", err);
                return of({err:"niepoprawne hasło"});
            }));
        })).subscribe((data) => {
            console.log("data", data);
            if (data.err) {
                setFormStatus("error");
                setErrorMessage(data.err);
              
            } else {
                  onChange();
            }
        })

        return () => requestSubscribe.unsubscribe();

    }, [onChange]);

    return (
        <div style={{ padding: "3rem" }}>
            {formStatus === "error" && <Alert style={{ marginBottom: "1rem" }} message={errorMessage} type="error" showIcon />}
            {formStatus !== "checking" && <Form
                name="basic"
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 12,
                }}
                initialValues={{
                    remember: true,
                }}
                onChange={() => setFormStatus(null)}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Stare hasło"
                    name="password1"
                    rules={[
                        {
                            required: true,
                            message: 'Proszę wpisz stare hasło!',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label="nowe hasło"
                    name="password2"
                    rules={[
                        {
                            required: true,
                            message: 'Proszę wpisz nowe hasło!',
                        },
                    ]}
                >
                    <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="Potwiedz hasło"
                        name="password3"
                        rules={[
                            {
                                required: true,
                                message: 'Proszę powtórz hasło!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 6,
                            span: 12,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            Zatwierdz
                        </Button>
                    </Form.Item>
            </Form>}
            {formStatus === "checking" && <Spin style={{ textAligin: 'left', margin: '2rem' }} size="large" />}
        </div>
    );
};
export default Edition;