import { useParams } from "react-router-dom";
import { Spin } from 'antd';

import { ChartPage } from "./chartPage";
import { useState,  useCallback } from "react";



function Charts({baseData, dirs}) {
   const { num } = useParams();
   const [hiddenLoader, setHiddenLoader] = useState();
   const hideLoader = useCallback((hide)=>{
      setHiddenLoader(hide);
   }, []);

   return (<>
   {baseData && <ChartPage onHideLoader={hideLoader} dirs={dirs} baseData={baseData}  num={+num} waitUntilCount={!hiddenLoader} />}
      {!hiddenLoader && <Spin size="large" />}</>)
}

export default Charts;