import { ajax } from 'rxjs/ajax';
import { of, Subject } from 'rxjs';
import { concatMap, takeWhile, reduce, expand, shareReplay, pluck, delay, tap, map } from 'rxjs/operators';

const domian = window.location.origin === "http://localhost:3000" ? "https://skyrep.pl" : window.location.origin;
//const domian =  "http://skyrep.pl";
const baseUrl = domian + "/user/wyslij.php";
const frequency = 60000;
const formDataDir = new FormData();
formDataDir.append("dirs","skyrep");

class HttpClient {
   constructor(){
      console.log("HttpClient");
   }
   $clearDate = new Subject();
   dataBaseName = "";
   allData$;

   getdataq() { console.log('data'); }

   allDirs$ = ajax({method: 'POST', url: domian + "/ipad/exeltis/files.php", body: formDataDir, headers: {
      "Access-Control-Allow-Headers": "X-Requested-With",
 "X-Requested-With": "XMLHttpRequest"       
    }}).pipe(pluck('response'),shareReplay(1));

    getAllData(name){
      if(name !== this.dataBaseName){
         this.dataBaseName = name;
         console.log("this.dataBaseName", this.dataBaseName);
         this.$clearDate.next();
         this.allData$ = of(0).pipe(expand(val => of(++val).pipe(delay(0))),
               concatMap((num) => this.getPartData(num).pipe(pluck('response'))),
               takeWhile((results) => {
                  console.log("results.length", results.length);
                  return results.length === frequency}, true),
                  tap((r)=>console.log("przeszlo",r)),
               reduce((a, b) => a.concat(b), []),
               shareReplay(1));
         
      }
   

         return this.allData$;

    }

   getPartData(numerPolaczenia) {
      console.log("numerPolaczenia",numerPolaczenia)
      const formData = new FormData();
      formData.append('czytanie[]', this.dataBaseName);
      formData.append('czytanie[]',numerPolaczenia);
      return ajax({method: 'POST', url: baseUrl, body: formData, headers: {
         "Access-Control-Allow-Headers": "X-Requested-With",
    "X-Requested-With": "XMLHttpRequest" 
       }});
   }

   logIn({username, password}) {
      const formData = new FormData();
      formData.append('username',username);
      formData.append('password',password);

      return ajax({method: 'POST', url: baseUrl, body: formData, headers: {
         "Access-Control-Allow-Headers": "X-Requested-With",
    "X-Requested-With": "XMLHttpRequest"    
       }}).pipe(map((res)=>res.response));

   }

   changeLogIn({username, password1, password2}) {
      const formData = new FormData();
      formData.append('username',username);
      formData.append('password1',password1);
      formData.append('password2',password2);

      return ajax({method: 'POST', url: baseUrl, body: formData, headers: {
         "Access-Control-Allow-Headers": "X-Requested-With",
    "X-Requested-With": "XMLHttpRequest"       
       }}).pipe(map((res)=>{
         return res.response === "ok" ? "ok" : {err:"niepoprawne hasło"}
      }));

   }

}
export const httpClient = new HttpClient();