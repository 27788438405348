import { memo, useState, useEffect } from "react"
import {Button, DatePicker, Form, InputNumber, notification ,TimePicker} from 'antd';
import {setting$} from "./../../providers/data";

function StatSetting({ setting }) {
    console.log("setting", setting);
    const [formStatus, setFormStatus] = useState();
    const [form] = Form.useForm();

    const [api, contextHolder] = notification.useNotification();

    const format = 'HH:mm';

    const openNotification = (placement) => {
      api.info({
        message: `Sukces`,
        description:
          'Twoje zmiany zostały zapisane pomyslnie!',
        placement,
      });
    };

    const onFinish = (setting) => {
        setting$.next(setting)
        openNotification('bottom');
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(()=>{
        form.setFieldsValue(setting);

    },[setting])

    return <>{contextHolder}<Form
    form={form}
        name="basic"
        labelCol={{
            span: 16,
        }}
        wrapperCol={{
            span: 12,
        }}
        initialValues={{
            remember: true,
        }}
        onChange={() => setFormStatus(null)}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
    >
        <Form.Item
            label="Maxymalny czas spędzony na slajdzie w min"
            name="maxSlideTime"
            rules={[
                {
                    required: true,
                    message: 'Proszę wpisz czas w min',
                },
            ]}
        >
            <InputNumber min={1} />
        </Form.Item>
        <Form.Item
            label="początkowa data"
            name="startDate"
            rules={[
                {
                    required: true,
                    message: 'Proszę wpybierz datę!',
                },
            ]}
        >
              <DatePicker/>
        </Form.Item>
        <h3>zakres czasowy w ciągu dnia</h3>
        <Form.Item
            label="czas startu pracy"
            name="startTime"
            rules={[
                {
                    required: true,
                    message: 'Proszę ustaw czas!',
                },
            ]}
        >
              <TimePicker format={format} />
        </Form.Item>

        <Form.Item
            label="czas zakończenia pracy"
            name="endTime"
            rules={[
                {
                    required: true,
                    message: 'Proszę ustaw czas!',
                },
            ]}
        >
              <TimePicker format={format}/>
        </Form.Item>

        <Form.Item
            wrapperCol={{
                offset: 6,
                span: 12,
            }}
        >
            <Button type="primary" htmlType="submit">
                Zatwierdz
            </Button>
        </Form.Item>
    </Form></>

}

export default memo(StatSetting);