import { Transfer } from 'antd';
import { useEffect, useState } from 'react';
import { przeliczenia } from "../../../providers/przeliczenia.js";
import { httpClient } from "../../../providers/httpClient.js";


const PrezEdit = ({customSlides, onChosenSlides}) => {

  const [allSlides, setAllSlides] = useState([]);

  const initAllData = (dataBase) => {
    const allSlideNames = przeliczenia.pobierzNazwySlajdow(dataBase);
    const slides = allSlideNames.map((slide) => ({
      key: slide,
      title: slide,
      chosen: false,

    }))

    setAllSlides(slides);
  };

  useEffect(() => {
    const subscribe = httpClient.allData$.subscribe(initAllData);
    return () => subscribe.unsubscribe();
  }, []);

  const filterOption = (inputValue, option) => option.title.indexOf(inputValue) > -1;

  const slidesChanged = (slides) => {
    const newSlides = slides.sort((a,b)=>{
      return getIndexOrder(a) - getIndexOrder(b)
    })
    onChosenSlides(newSlides);

  }

  const getIndexOrder = (slide) => {
    const index = customSlides.indexOf(slide);
    return index < 0 ? customSlides.length  : index;
  }

  console.log("render transfer");

  return (
    <Transfer
      dataSource={allSlides}
      showSearch
      filterOption={filterOption}
      targetKeys={customSlides}
      onChange={slidesChanged}
      render={(item) => item.title}
      listStyle={{
        width: 400,
        height: 300,
      }}
    />
  );
};

export default PrezEdit;