import { Button } from 'antd';
import { useState } from 'react';
import Edition from './edition';
import StatSetting from './statSetting';
import { Card } from 'antd';

const Setting = ({ user, setting }) => {
    const [changePass, setChangePass] = useState(false);
    const changedPass = () => setChangePass(false);
    const onClickChangePass = () => setChangePass(true);

    return (
        <>
        <Card title="Ustawienia konta" style={{ width: 600 }}>
            <p>{user}</p>
            {changePass ? <Edition user={user} onChange={changedPass} /> : <Button onClick={onClickChangePass}>Zmień hasło</Button>}
        </Card>
          <Card title="Ustawienia statystyk" style={{ width: 600, marginTop:"2rem" }}>
          <StatSetting setting={setting} />
      </Card>
      </>
    );
};
export default Setting;