import {
    // rest of the elements/components imported remain same
    useParams, useNavigate
} from 'react-router-dom';

export const customHooks = {
    useNavigate: { navigate: useNavigate },
    useParams: { params: useParams }
}

export function withCustomHooks(Component, ...params) {
    const hookedParams = (params) => params.reduce((a, b) => {
        const [prop, hookFunction] = Object.entries(b)[0];
        a[prop] = hookFunction();
        return a;
    }, {})
    return props => <Component {...props} {...hookedParams(params)} />;
}