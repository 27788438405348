import React from "react";

import { Card } from 'antd';
import 'antd/dist/antd.min.css';
import { addStyle } from '../../../providers/styles.js'
import style from './weeklyStats.internal.css';
import { withCustomHooks, customHooks } from '../../../providers/warpHooks.js'




const gridStyle = {
  width: '20%',
  textAlign: 'center'
};

class WeeklyStats extends React.Component {

  navigate;
  days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
  representans = [{ name: 'michal bagsiski', vizits: [1, 2, 3, 4, 5] }, { name: 'Pan batman', vizits: [1, 2, 3, 4, 5] }];

  constructor(props) {
    super(props);
    this.navigate = this.props.navigate;
    console.log('constructor weekly');

    console.log('props', this.props);
    addStyle.call(this, style);
  }

  showStats = (e) => {
    const [day, rep] = e.target.getAttribute("data-visit").split("##");
    const selectedDate = this.props.chosenDate.clone().add(day - 5, 'days');
    console.log("selectedDate", selectedDate.format('MM-DD-YYYY'));
    console.log('rep:', rep, day);
    const statData = this.props.weekData.get(rep)[day];
    this.navigate("../day",{state:{rep,date:selectedDate.format('MM-DD-YYYY'),statData}});
  }


  render() {
    console.log("this.props.weekData.size", this.props.weekData.size);
    return (<div className="main-stat">
      {this.props.weekData.size ? <div className="rep-list"><div>Przedstawiciele</div>
        {Array.from(this.props.weekData.keys()).map((rep) => {
          return <div className="repr" key={"rep_" + rep}>{rep}</div>
        })}
      </div> : ""}
      <div className="rep-stat">
        <div className="days">{this.days.map((day, index) => <div key={"dy" + index} className="day">{day}</div>)}</div>
        {this.props.weekData.size ?
          <Card>{Array.from(this.props.weekData.entries()).map(([rep, visits]) => {
            return Array.from(this.days.keys()).map(key => {
              return <Card.Grid key={"card_" + rep + "_" + key} style={gridStyle} data-visit={key + "##" + rep} onClick={this.showStats}>{visits[key]?.length ?? 0}</Card.Grid>
            })
          })
          }
          </Card> : <p>Brak danych dla {this.props.chosenDate.week()} tygodnia</p>}
      </div></div>);
  }
  componentWillUnmount() {
    console.log('unmount');
  }
}

export default withCustomHooks(WeeklyStats, customHooks.useNavigate, customHooks.useParams);

