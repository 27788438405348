import { memo, useState, useEffect } from "react";
import {Subscription} from "rxjs";
import styled from '@emotion/styled';
import { Groups } from './pages/grups/grups';
import { Stats } from './pages/stats/stats';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import TopNavigation from './TopNavigation';
import LeftNavigation from "./LeftNavigation";
import Export from "./pages/export/export";
import LogIn from "./pages/login/logIn";
import Setting from "./pages/setting/setting";          
import {user$, setting$} from "./providers/data";
import { przeliczenia } from "./providers/przeliczenia";
import Database from "./pages/database/database";
import * as moment from 'moment';

const AppStyled = styled.div`

section {
  display: flex;
  aside{
  width:240px;
  }
  article{
    margin-top: 1.5rem;
    width: 100%;
  }
  ul{
    width: 100%;
  }
}
`

const DEFAULT_DATA_BASE = "slajdy150";
const DEFAULT_SETTING = {maxSlideTime: 10, startDate: moment(new Date("01.01.2023")), startTime: moment().set({"hour": 7, "minute": 0}), endTime: moment().set({"hour": 18, "minute": 0})};

function App() {
  const [user, setUser] = useState({});
  const [dataBaseName, setDataBasename] = useState(DEFAULT_DATA_BASE);
  const [setting, setSetting] = useState(DEFAULT_SETTING)

  useEffect(()=>{

    if(localStorage["setting"]){
      const [maxTime, startD, start, end] = localStorage["setting"].split(";");
      console.log("startD", startD);
      const newSetting = {maxSlideTime: +maxTime, startDate: moment(new Date(startD)), startTime: moment(new Date(+start)), endTime: moment(new Date(+end))}
      window.koko = newSetting;
      console.log("newSetting", newSetting)
      setSetting(newSetting);
    }
    const subscription = new Subscription();

    subscription.add(setting$.subscribe((setting)=>{
      const stringSetting = setting.maxSlideTime + ";" + setting.startDate.format("MM.DD.YYYY") + ";" + setting.startTime + ";" + setting.endTime;
      localStorage["setting"] = stringSetting;
      setSetting(setting);
    }))
    subscription.add(user$.subscribe(setUser));
    return ()=>subscription.unsubscribe();

  },[])

  useEffect(()=>{
    if(localStorage["basename"]){
      console.log("user.status",user.status);
      const newBase = user.status === "admin" ? localStorage["basename"] : DEFAULT_DATA_BASE;
      console.log("newBase", newBase);
      setDataBasename(newBase);
    }
  },[user])

  useEffect(()=>{
    console.log("setting", setting);
    przeliczenia.maxSlideTime = setting.maxSlideTime * 60 * 1000;
    przeliczenia.defaultStartData = setting.startDate;

    console.log("setting.startTime" , setting.startTime?.format("HH.mm"));
    przeliczenia.startTime = +setting.startTime?.format("HH.mm");
    przeliczenia.endTime = +setting.endTime?.format("HH.mm");
  },[setting]);

  const changeDataBase = (name) => {
    console.log("name", name);
    localStorage["basename"] = name;
    setDataBasename(name);
  };

  console.log("chandetect", dataBaseName);

  return (
    <AppStyled>
      <Router>
       <TopNavigation user={user.name}></TopNavigation>
        <section>
          {user.name && <LeftNavigation />}
          <article>

            <Routes>
              

              <Route path="setting" element={<Setting user={user.name} setting={setting} />} ></Route>
              
              <Route path="export" element={<Export />} ></Route>
         
              <Route path="stats/*" element={<Stats dataBaseName={dataBaseName} />} ></Route>

              <Route path="database" element={<Database userStatus={user.status} dataBaseName={dataBaseName} onChange={changeDataBase} />} ></Route>

              <Route path="groups" element={<Groups dataBaseName={dataBaseName} />} />

              <Route path="login" element={<LogIn />} ></Route>
          
           
           
            </Routes>
          </article>
        </section>

      </Router>
    </AppStyled>
  );
}

export default memo(App);
