import React from "react";
import Week from "./weekly-stat/week.js";
import Charts from "./charts"
import { DaylyStats } from "./dayly-stats/daylyStats.js";
import { Route, Routes } from 'react-router-dom';
import { httpClient } from "../../providers/httpClient.js";
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, Subject, Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import { przeliczenia } from "../../providers/przeliczenia.js";
import { ThemeContext } from "@emotion/react";



export class Stats extends React.Component {

  state = { weekData: null, chosenDate: moment(), loading: true, customPrez: { active: false, slides: [], order: false } }
  dirs = [];
  subscribe = new Subscription();
  baseData;
  dataBaseName;

  changedWeek$ = new Subject();
  customPrez$ = new BehaviorSubject(this.state.customPrez);
  nazwySlajdow = [];

  changeCustomPrez = (prez) => {
    const customPrez = { ...this.state.customPrez, ...prez }
    this.setState({ ...this.state, customPrez })
    this.customPrez$.next(customPrez);
  }


  weekChange = (chosenDate) => {
    this.changedWeek$.next(chosenDate)
    this.setState((prev) => ({ ...prev, chosenDate }));
    console.log('week changed');
  }

  constructor(props) {
    super();
    this.dataBaseName = props.dataBaseName;
    console.log('nowy', this.dataBaseName);
  }

  componentDidMount() {
    console.log('mount 1', this.dataBaseName);
    this.subscribe.add(httpClient.$clearDate.subscribe(()=>przeliczenia.minDate = null));


    this.subscribe.add(combineLatest(this.changedWeek$, httpClient.getAllData(this.dataBaseName).pipe(tap(allData => {
      this.baseData = allData;
    })), this.customPrez$, httpClient.allDirs$).subscribe(([week, allData, customPrez, dirs]) => {
      console.log('allData', allData, week);
      this.dirs = dirs;
      if (week) {
        const startDate = week.startOf('week').valueOf();
        const endDate = week.endOf('week').valueOf();
        console.log(startDate, endDate);
        const weekData = przeliczenia.przeliczWlaczeniaWizytNaDni(allData, startDate, endDate, customPrez);
        this.setState((prev) => ({ ...prev, weekData, loading: false }));
      } else {
        this.setState((prev) => ({ ...prev, weekData: null, loading: false }));
      }
    }));
    this.changedWeek$.next(this.state.chosenDate);
  }

  componentWillUnmount() {
    this.subscribe.unsubscribe();
  }

  render() {
    console.log('render stat', this.state);
    return <Routes>
      <Route path="day" element={<DaylyStats dirs={this.dirs} />}></Route>
      <Route path="chart/:num" element={<Charts baseData={this.baseData} dirs={this.dirs} />}></Route>
      <Route path="week/*" element={<Week onChangeCustomPrez={this.changeCustomPrez} onWeekChange={this.weekChange} {...this.state} />}></Route>
    </Routes>
  }


}