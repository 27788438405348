class Przeliczenia {
 
    noSlajd = ["resume", "pause", "wlaczenie", "zamknieto", "visit", "page", "save tracking", "startowy", "exit","?","przew","logi",1,2,3,4,5,6,7,8,9,0];
    minDate;
    maxSlideTime = 10 * 60 * 1000;
    startTime;
    endTime;
    constructor(){
        console.log('construct przeliczenia');
    }

    getSyncTime(database, persons) {
        const personsTime = new Map();
        let i = database.length
       while(persons.length !== 0 && i > 0) {
        i--;
       const personIndex = persons.indexOf(database[i][3]);
       if(personIndex !== -1) {
        persons.splice(personIndex,1);
        personsTime.set(database[i][3], database[i][5])
       }
       }
       return personsTime;
    }



 
    przeliczCzasyWizyt(database, minMax, groups, zPauzami = true) {
        console.log("startTime", this.startTime);
        console.log("przelicz", minMax);
        console.log("this.maxSlideTime", this.maxSlideTime);
        const tablicaWizytyCzasy = new Map();
        const tablicaZaczetych = new Map();
        const tablicaZaczetychPauz = new Map();
        const pauseTime = new Map();
        const tablicaOstatnichAktywnosci = new Map();
        const debugData = [];

        const closeVisit=(row, user)=> {
            if(user === "bartlomiej kapala"){
                console.log("oststnia aktywnsosc:", tablicaOstatnichAktywnosci.get(user),row);
            }
            const oststniaAktywnosc = tablicaOstatnichAktywnosci.get(user);
                if(oststniaAktywnosc) {
                    let czasAktywnosci = +row[2] - oststniaAktywnosc[1];
                    if (pauseTime.has(user)) {
                        if(zPauzami){
                            czasAktywnosci -= pauseTime.get(user);
                        } else{
                            czasAktywnosci += pauseTime.get(user);
                        }
                        pauseTime.delete(user);
                        tablicaZaczetychPauz.delete(user);
                    }
                    const czasAkwtywnosciZlimitem = czasAktywnosci > this.maxSlideTime ? this.maxSlideTime : czasAktywnosci;
                    const allTime = tablicaZaczetych.get(user) + czasAkwtywnosciZlimitem;
                    if(user === "bartlomiej kapala"){
                        console.log("row", row,czasAktywnosci,allTime);
                    }
                    const existingTime = tablicaWizytyCzasy.get(user);
                    if (existingTime) {
                        tablicaWizytyCzasy.set(user, existingTime + allTime);
                    } else {
                        tablicaWizytyCzasy.set(user, allTime);
                    }
                  
                    tablicaOstatnichAktywnosci.delete(user);
                } 
                tablicaZaczetych.delete(user);
        }
        console.log("database", database.length)
        
        database.forEach((row) => {
            if(!groups || groups.includes(row[3])) {
            const user = this.getUserName(row[3]);
            const badTime = this.checkMinDate(row, minMax);
            if(badTime){
                if(tablicaZaczetych.has(user)){
                closeVisit(row, user);
                }
                return;
            }
            if(user === "bartlomiej kapala"){
                debugData.push([...row, this.milisecondsToTime(tablicaWizytyCzasy.get(user),0)]);
            }
            if (row[6].startsWith("visit-start")) {
                tablicaZaczetych.set(user, 0);
                tablicaZaczetychPauz.delete(user);
                tablicaOstatnichAktywnosci.delete(user);
                pauseTime.delete(user);
            } else if (row[6].startsWith("visit-end") || row[6].startsWith("startowy")) {
                closeVisit(row, user);
            } else if (row[6].startsWith("pause") && tablicaZaczetych.has(user)) {
                if (tablicaZaczetych.has(user)) {
                    tablicaZaczetychPauz.set(user, [row[1], row[2]]);
                }
            }
            else if (row[6].startsWith("resume") && tablicaZaczetych.has(user)) {
                if (tablicaZaczetychPauz.has(user)) {
                    let czasPauzy = +row[2] - tablicaZaczetychPauz.get(user)[1];
                    if (pauseTime.has(user)) {
                        czasPauzy += pauseTime.get(user);
                    }
                    pauseTime.set(user, czasPauzy);
                    tablicaZaczetychPauz.delete(user);
                } else {
                    let czasPauzy = +row[2] - row[1];
                    if (pauseTime.has(user)) {
                        czasPauzy += pauseTime.get(user);
                    }
                    pauseTime.set(user, czasPauzy);
                }
            } else if(tablicaZaczetych.has(user)){
                const slajdName = this.getSlajdname(row[6]);
                if(user === "bartlomiej kapala"){
                   console.log("slajdName", slajdName, row, tablicaOstatnichAktywnosci.get(user))
                }
                const oststniaAktywnosc = tablicaOstatnichAktywnosci.get(user);
                if(oststniaAktywnosc) {
                    let czasAktywnosci = +row[2] - oststniaAktywnosc[1];
                    if (pauseTime.has(user)) {
                        if(zPauzami) {
                            czasAktywnosci -= pauseTime.get(user);
                        } else {
                            czasAktywnosci += pauseTime.get(user);
                        }
                        
                        pauseTime.delete(user);
                        tablicaZaczetychPauz.delete(user);
                    }
                    const allTime = tablicaZaczetych.get(user);
                    const czasAkwtywnosciZlimitem = czasAktywnosci > this.maxSlideTime ? this.maxSlideTime : czasAktywnosci;
                    if(isNaN(allTime + czasAkwtywnosciZlimitem)) {
                        console.error("err", row);
                        console.log("allTime", allTime);
                        console.log("oststniaAktywnosc", oststniaAktywnosc)
                    }
                    tablicaZaczetych.set(user, allTime + czasAkwtywnosciZlimitem);
                   
                    tablicaOstatnichAktywnosci.set(user,[slajdName, +row[2]]);
               
            } else if(slajdName){
                tablicaOstatnichAktywnosci.set(user,[slajdName, +row[2]]);
            }  
            }
        }
        });
        console.log("tablicaWizytyCzasy", tablicaWizytyCzasy);
        console.log("debugData", debugData);
   
        return tablicaWizytyCzasy;
    }


    przeliczWlaczeniaWizyt(database,minMax, groups) {
        const tablicaWizytyWlaczen = new Map();
        const tablicaZaczetych = new Map();
        console.log('przeliczWlaczeniaWizyt', groups);

        database.forEach((row) => {
            if(!groups || groups.includes(row[3])) {
                if(this.checkMinDate(row, minMax)){
                    return;
                }
            if (row[6].startsWith("visit-start")) {
                const user = this.getUserName(row[3])
                tablicaZaczetych.set(user, [row[1], row[2],row[6]]);
            } else if (row[6].startsWith("visit-end") || row[6].startsWith("startowy")) {
                const user = this.getUserName(row[3]);
                const czasyZaczetej = tablicaZaczetych.get(user);
                if (czasyZaczetej) {
                    if (czasyZaczetej[1] !== row[1] && !czasyZaczetej[2].startsWith("visit-start")) {
                        let liczbaWizyt = 1;
                        if (tablicaWizytyWlaczen.has(user)) {
                            liczbaWizyt += tablicaWizytyWlaczen.get(user);
                        }
                        tablicaWizytyWlaczen.set(user, liczbaWizyt)
                    }
                    tablicaZaczetych.delete(user);
                }
            } else if(this.getSlajdname(row[6])){
                const user = this.getUserName(row[3]);
                const czasyZaczetej = tablicaZaczetych.get(user);
                if (czasyZaczetej) {
                    czasyZaczetej[2] = row[6];
                }

            }
        }
    });
        return tablicaWizytyWlaczen;
    }

    przeliczWlaczeniaWizytNaDni(database, start, end, customPrez) {
        const tablicaWizytyWlaczen = new Map();
        const tablicaZaczetych = new Map();
        database.forEach((row) => {
          
            if (row[6].startsWith("visit-start") && row[1] > start && row[1] <= end) {
                const user = this.getUserName(row[3])
                tablicaZaczetych.set(user, [[row[1], row[2], row[6]]]);

            } else if (row[6].startsWith("visit-end") || row[6].startsWith("startowy")) {
                const user = this.getUserName(row[3]);
                const zaczetoJuzWizyte = tablicaZaczetych.get(user);
                if (zaczetoJuzWizyte && this.customPrezIncludeSlides(zaczetoJuzWizyte, customPrez)) {
                    zaczetoJuzWizyte.push([row[1], row[2], row[6]]);
                    const czasyZaczetej = zaczetoJuzWizyte[0];
                    if (czasyZaczetej[1] !== row[1] && !row[6].startsWith("visit-start")) {
                        let visits = [];
                        const dzien = this.getWeekdayFromMiliSeconds(czasyZaczetej[1]);

                        if (tablicaWizytyWlaczen.has(user)) {
                            visits = tablicaWizytyWlaczen.get(user);
                            if (!visits[dzien]) {
                                visits[dzien] = [zaczetoJuzWizyte]

                            } else {
                                visits[dzien].push(zaczetoJuzWizyte);
                            }
                        } else {
                            visits[dzien] = [zaczetoJuzWizyte];
                            tablicaWizytyWlaczen.set(user, visits)
                        }
                    }
                    tablicaZaczetych.delete(user);
                }
            } else if (this.getSlajdname(row[6]) && row[1] > start && row[1] <= end) {
                const user = this.getUserName(row[3]);
                const userActivity = tablicaZaczetych.get(user);
                if (userActivity) {
                    userActivity.push([row[1], row[2], row[6]])
                }

            }
        });
        return tablicaWizytyWlaczen;
    }

    customPrezIncludeSlides(zaczetoJuzWizyte, {active, slides, order}) {
        if(active && slides.length) {
            const slidesInVisit = zaczetoJuzWizyte.map((row)=>row[2]);
            if(order){
            const requireSlides = slides.join("##");
            return slidesInVisit.join("##").includes(requireSlides)
            }
            return slides.every(slide=>slidesInVisit.includes(slide));
        }
        return true;
    }

    przeliczPrezentacjeWlaczenia(database, minMax) {
        const tablicaPrezentacjiWlaczen = new Map();
        database.forEach(row => {
            if(this.checkMinDate(row, minMax)){
                return;
            }
            if (row[6].startsWith("wlaczenie")) {
                const nazwaPrezentacji = row[6].substr("wlaczenie".length);
                let liczbaWlaczen = 1;
                if (tablicaPrezentacjiWlaczen.has(nazwaPrezentacji)) {
                    liczbaWlaczen = tablicaPrezentacjiWlaczen.get(nazwaPrezentacji) + 1;
                }
                tablicaPrezentacjiWlaczen.set(nazwaPrezentacji, liczbaWlaczen);

            }
        });
        return tablicaPrezentacjiWlaczen;
    }

    przeliczPrezentacjeCzasy(database, minMax) {
        const tablicaPrezentacjiczasy = new Map();
        let aktuanaPrezentacja = '';
        let aktualnyCzasPrezentacji = 0;

        database.forEach(row => {
            if(this.checkMinDate(row, minMax)){
                return;
            }
            if (row[6].startsWith("wlaczenie")) {
                aktuanaPrezentacja = row[6].substr("wlaczenie".length);
                aktualnyCzasPrezentacji = 0;
                if (tablicaPrezentacjiczasy.has(aktuanaPrezentacja)) {
                    aktualnyCzasPrezentacji = tablicaPrezentacjiczasy.get(aktuanaPrezentacja);
                }
            } else if (row[6].startsWith("zamknieto")) {
                if(aktuanaPrezentacja){
                tablicaPrezentacjiczasy.set(aktuanaPrezentacja, aktualnyCzasPrezentacji + this.getTimePeriod(row));
                }
                aktuanaPrezentacja = '';
            } else if (aktuanaPrezentacja && (row[6] !== 'resume' && !row[6].startsWith("page") && !row[6].startsWith("save tracking") && !row[6].startsWith("startowy"))) {
                tablicaPrezentacjiczasy.set(aktuanaPrezentacja, aktualnyCzasPrezentacji + this.getTimePeriod(row));
            }
        });
        return tablicaPrezentacjiczasy;
    }

    przeliczSrednieCzasySlajdow(database, minMax) {
        const tablicaSlajdowCzasy = new Map();
        let aktuanaPrezentacja = "";
        const nazwyPrezentacji = new Map();
        database.forEach(row => {
            if (row[6].startsWith("wlaczenie")) {
                aktuanaPrezentacja = row[6].substr("wlaczenie".length);
            }
            if(this.checkMinDate(row, minMax)){
                return;
            }
            const nazwaSlajdu = this.getSlajdname(row[6]);
            if (nazwaSlajdu) {
                let liczbaWlaczen = 1;
                let czasSlajdu = Math.min(+row[2] - row[1], this.maxSlideTime);
                if (tablicaSlajdowCzasy.has(nazwaSlajdu)) {
                    liczbaWlaczen = tablicaSlajdowCzasy.get(nazwaSlajdu)[0] + 1;
                    czasSlajdu += tablicaSlajdowCzasy.get(nazwaSlajdu)[1];
                }
                else {
                    nazwyPrezentacji.set(nazwaSlajdu, aktuanaPrezentacja);
                }
                tablicaSlajdowCzasy.set(nazwaSlajdu, [liczbaWlaczen, czasSlajdu]);

            }
        });
        console.log('tablicaSlajdowCzasy', tablicaSlajdowCzasy);
        var nTab = Array.from(tablicaSlajdowCzasy.entries()).map(([key, [num, time]]) => [key, Math.ceil(time / (num * 1000))]);
        console.log('nTab', nTab)
        return [new Map(nTab), nazwyPrezentacji];
    }

    przeliczWlaczeniaPrezentacji(database,minMax, prez) {
        const tablicaWizytyWlaczen = new Map();
        const tablicaPrezWlaczen = new Map();
        const tablicaZaczetych = new Map();
        const tablicaZaczetychPrez = new Map();

        database.forEach((row) => {

            if(this.checkMinDate(row, minMax)){
                return;
            }

            if (row[6].startsWith("visit-start")) {
                const user = this.getUserName(row[3])
                tablicaZaczetych.set(user, [row[1], row[2], row[6]]);
                tablicaZaczetychPrez.set(user, new Set());
            } else if (row[6].startsWith("visit-end") || row[6].startsWith("startowy")) {
                const user = this.getUserName(row[3]);
                const czasyZaczetej = tablicaZaczetych.get(user);
                const slajdyWyswietloneDlaPrez = tablicaZaczetychPrez.get(user);
                if (czasyZaczetej) {
                    if (czasyZaczetej[1] !== row[1] && !czasyZaczetej[2].startsWith("visit-start")) {
                        let liczbaWizyt = 1;
                        if (tablicaWizytyWlaczen.has(user)) {
                            liczbaWizyt += tablicaWizytyWlaczen.get(user);
                        }
                        tablicaWizytyWlaczen.set(user, liczbaWizyt)
                        let wlaczPrez = tablicaPrezWlaczen.get(user);
                        if (!wlaczPrez) {
                            wlaczPrez = []
                            tablicaPrezWlaczen.set(user, wlaczPrez);
                        }
                        if (slajdyWyswietloneDlaPrez.size > 5) {
                            wlaczPrez.push(slajdyWyswietloneDlaPrez)
                        }
                    }
                    tablicaZaczetych.delete(user);
                    tablicaZaczetychPrez.delete(user);
                }
            } else if (this.getSlajdname(row[6])) {
                const user = this.getUserName(row[3]);
                const czasyZaczetej = tablicaZaczetych.get(user);
                if (czasyZaczetej) {
                    czasyZaczetej[2] = row[6];
                }

                if (row[6].includes(prez)) {
                    const slajdy = tablicaZaczetychPrez.get(user);
                    if (slajdy && row[6].slice(-2) !== "07" && row[6].slice(-2) !== "08") {
                        slajdy.add(row[6])
                    }
                }

            }

        });
        return tablicaPrezWlaczen;
    }

    pobierzNazwySlajdow(database) {
        const tablicaSlajdy = []
        database.forEach(row => {
            const nazwaSlajdu = this.getSlajdname(row[6]);
            if(nazwaSlajdu && !tablicaSlajdy.includes(nazwaSlajdu)){
                tablicaSlajdy.push(nazwaSlajdu)
            }
        });
        return tablicaSlajdy.sort()
    }

    przeliczSlajdyWlaczenia(database, minMax) {
        const tablicaSlajdyWlaczen = new Map();
        let aktuanaPrezentacja = "";
        const nazwyPrezentacji = new Map();
        database.forEach(row => {
            if (row[6].startsWith("wlaczenie")) {
                aktuanaPrezentacja = row[6].substr("wlaczenie".length);
            }
            if(this.checkMinDate(row, minMax)){
                return;
            }
            const nazwaSlajdu = this.getSlajdname(row[6]);

            if (nazwaSlajdu) {
                let liczbaWlaczen = 1;
                if (tablicaSlajdyWlaczen.has(nazwaSlajdu)) {
                    liczbaWlaczen = tablicaSlajdyWlaczen.get(nazwaSlajdu) + 1;
                } else {
                    nazwyPrezentacji.set(nazwaSlajdu,aktuanaPrezentacja);
                }
                tablicaSlajdyWlaczen.set(nazwaSlajdu, liczbaWlaczen);
            }
        });
        return [tablicaSlajdyWlaczen, nazwyPrezentacji];
    }

    przeliczDatySynchronizacji(database) {
        const tabSynch = [];
        let lastUser;
        let lastSync
        let eventNum = 0;
        database.forEach(row => {
            if (lastSync !== row[5] || lastUser !== row[3]) {
                if (eventNum) {
                    tabSynch.push([lastUser, lastSync, "success for " + eventNum + " trackEvents"])
                }
                lastUser = row[3];
                lastSync = row[5]
                eventNum = 1;
            } else {
                eventNum++;
            }

        });
        return tabSynch;
    }

    przeliczWizytyDziennie(database, minMax) {
        const tablicaWizytyWlaczen = new Map();
        const tablicaZaczetych = new Map();
        database.forEach((row) => {
            if(this.checkMinDate(row, minMax)){
               return;
            }
            if (row[6].startsWith("visit-start")) {
                const user = row[3];
                tablicaZaczetych.set(user, [row[1], row[2], row[6]]);
            } else if (row[6].startsWith("visit-end") || row[6].startsWith("startowy")) {
                const user = row[3];
                const czasyZaczetej = tablicaZaczetych.get(user);
                if (czasyZaczetej) {
                    if (czasyZaczetej[1] !== row[1] && !czasyZaczetej[2].startsWith("visit-start")) {
                        let liczbaWizyt = 1;
                        const day = this.getDayFromMiliSeconds(czasyZaczetej[1]);
                        if (tablicaWizytyWlaczen.has(user + "%%" + day)) {
                            liczbaWizyt += tablicaWizytyWlaczen.get(user + "%%" + day);
                        }

                        tablicaWizytyWlaczen.set(user + "%%" + day, liczbaWizyt);
                    }
                    tablicaZaczetych.delete(user);
                }
            } else if (this.getSlajdname(row[6])) {
                const user = row[3];
                const czasyZaczetej = tablicaZaczetych.get(user);
                if (czasyZaczetej) {
                    czasyZaczetej[2] = row[6];
                }

            }
        });

        const tabWizytByDay = Array.from(tablicaWizytyWlaczen).map(([key, val]) => {
            const row = key.split("%%");
            row.push(val);
            return row;
        });
        return tabWizytByDay;

    }

    milisecondsToTime(mili, minimum=1) {
        const seconds = Math.round(mili / 1000);
        const min = Math.floor(seconds / 60);
        const hours = Math.floor(min / 60);
        return this.getTimePart(hours, "h : ")  + this.getTimePart(min % 60, "min ") + " " + this.getTimePart(seconds % 60, "s", minimum);
    }

    getTimePart(num, ending, deviation = 0) {
        if(deviation && num + deviation !== 60){
            num = num + deviation;
        }
        if(num){
                if(num >= 10){
                    return num + ending;
                }
                return ("0" + num).slice(-2) + ending;
        }
       return  "";
    }


    checkMinDate(row, minMax){
        row[1] = row[1].substr(-13);
        const time = +row[1];

        
        if(!this.minDate || time<this.minDate){
            this.minDate = time;
        }
        

        if( !time || (time<minMax[0] || time>minMax[1]) || !this.corectTime(time)){
           return true;
        }

        return false;
       
    }

    corectTime(day){
        const currentTime = +this.getTimeFromMiliseconds(day);
        if(this.startTime && this.startTime > currentTime){
            return false
        }
        if(this.endTime && this.endTime < currentTime){
            return false
        }
        return true;
    }

    getTimeFromMiliseconds(mili){
        if(!mili){
            return undefined;
        }
        const date = new Date(+mili);
        return date.getHours() + "." + date.getMinutes();
    }


 


    getWeekdayFromMiliSeconds(mili) {
        const temp = new Date(+mili).getDay() - 1;
        return temp >= 0 ? temp : 6;
    }

    getDayFromMiliSeconds(mili) {
        const tempDate = new Date(+mili);
        const year = tempDate.getFullYear();
        let month = tempDate.getMonth() + 1 + "";
        if (month.length === 1) {
            month = "0" + month;
        }
        let day = tempDate.getDate() + "";
        if (day.length === 1) {
            day = "0" + day;
        }
        return year + "-" + month + "-" + day;
    }


    getFirstGoodTime(dataBase, index) {
        const part = dataBase.slice(0, index);
        const user = dataBase[index][3];
        const lastRow = part.find(row => row[3] === user);
        if (lastRow) {
            return lastRow[1];
        }
        return this.startPeriotTime;
    }

    getLastGoodTime(dataBase, index) {
        const part = dataBase.slice(0, index).reverse();
        const user = dataBase[index][3];
        const lastRow = part.find(row => row[3] === user);
        if (lastRow) {
            return lastRow[1];
        }
        return 0;
    }


    getSlajdname(slajd) {
        for (const sub of this.noSlajd) {
            if (slajd.startsWith(sub)) {
                return false;
            }
        }
        if(slajd.length <= 3 || slajd.includes(".php")){
            return false;
        }
        return slajd;
    }

    getTimePeriod(row) {
        return +row[2] - row[1];
    }

    getUserName(email) {
        const parts = email.split("@");
        return parts[0].replace(".", " ");
    }
}

export const przeliczenia = new Przeliczenia();





