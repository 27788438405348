
import {
    // rest of the elements/components imported remain same
    useLocation
} from 'react-router-dom';
import { startTransition, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Modal } from 'antd';
import {
    PictureOutlined,
  } from '@ant-design/icons';
import { przeliczenia } from "../../../providers/przeliczenia";
import VisitDetails from "./visitDetails";

const DaylyStatsStyled = styled.div`
h2{
    button{
        margin-left: 3rem;
    }
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color:white;
    margin: 0;
    & > span {
        text-transform: uppercase;
        margin: 0 0.5rem;
        
    }
    & > .count{
        color:black;
        font-size: 1.6em;
        background-color: white;
        padding: .3rem;
        line-height: 1em;
        margin: 0.5rem;
        font-weight: bold;

    }
}

.visit-stat{
    .slides-table{
        width: 50%;
        display: flex;
        justify-content: flex-end;
    }
    p{
        margin: 0;
        text-align: left;
    }
    &:nth-of-type(even){
        background: #eeeeee;
    }
    
    display: flex;
    align-items: flex-start;
    padding: 0.5rem;
    td,th{
        padding: 0 1rem;
        text-align: right;
    }
    .details{
        padding: 0 0 0 2rem;
    }
}


`;


export function DaylyStats({dirs}) {
    const { state: { rep, date, statData } } = useLocation();
    
    const [modalData, setModaldata] = useState();
    


  
    const handleOk = () => {
        setModaldata();
    };
  
    const handleCancel = () => {
        setModaldata();
    };

    const showPrev = (slide, dirName)=>{
        console.log(dirName);
        const prevUrl = window.location.protocol +"//node.skyrep.pl?slide=" + slide + "&dir=" + dirName
        console.log("prevUrl", prevUrl)
        const state = {slide, src: prevUrl}
     //   const state = {slide, src: "http://michal1-env.eba-32bmtkmd.eu-west-3.elasticbeanstalk.com/?slide=" + slide + "&dir=" + dirName}
        setModaldata(state);
    }
    const getPrev = (slide)=>{
        const slideFile = slide + ".zip";
        console.log("slideFile",slideFile);
        const path = dirs.find(dir=>dir[1].some(file=>file === slideFile));
        if(path){
            return <Button type="primary" style={{margin:"0.1rem"}}  icon={<PictureOutlined />} size="small" onClick={() => showPrev(slide, path[0])} />
        }
    }

    const maxTime = przeliczenia.milisecondsToTime(przeliczenia.maxSlideTime,0);



    return (<DaylyStatsStyled><h2> <span>{rep}</span> {date} <div className='count'>{statData?.length ?? 0}</div> wizyt  <Button onClick={() => window.history.back()}>Powrót</Button></h2>
        {statData && <div className='slides'>
            {statData?.map((visit, index) => {
                const slides = [...visit]
                const startV = slides.shift();
                const endV = slides.pop();
                let reducedVisitTime = 0;
                return <div className='visit-stat' key={"visit-" + startV[1]}>
                    <div className='slides-table'><table key={index + "_" + startV}><thead><tr><th>spędzony czas</th><th>nazwa aktywności</th><th>podgląd</th></tr></thead><tbody>{slides.map((slide, key) => {
                        const miliseconds = +visit[key + 2][1] - slide[1];
                        const minuts = miliseconds > przeliczenia.maxSlideTime ? maxTime : przeliczenia.milisecondsToTime(miliseconds);             
                        if(miliseconds > przeliczenia.maxSlideTime){
                            reducedVisitTime += przeliczenia.maxSlideTime;
                        } else {
                            reducedVisitTime += miliseconds;
                            const seconds = Math.round(miliseconds / 1000);
                            if(seconds%60 !== 59) {
                                reducedVisitTime += 1000;
                            } 
                        }
                        
                        
                        return <tr key={"slide-" + slide[2] + "-k-" + key}><td>{minuts}</td><td>{slide[2]}</td><td>{getPrev(slide[2])}</td></tr>
                    })}</tbody></table></div>
                    <VisitDetails reducedVisitTime={reducedVisitTime} startV={startV} endV={endV}/>
                    </div>
            })}
        </div>}
        <Modal width={1024} height={768} visible={modalData} title={modalData?.slide}  onOk={handleOk} onCancel={handleCancel}>
         <iframe title={modalData?.slide}  scrolling="no" width="100%" height="768px" frameBorder="0" marginHeight="0px" marginWidth="0px" src={modalData?.src}></iframe>
      </Modal>
    </DaylyStatsStyled >)
}