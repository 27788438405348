import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  plugins,
} from 'chart.js';
import { Modal } from 'antd';
import { Bar } from 'react-chartjs-2';
import styled from '@emotion/styled';
import { przeliczenia } from "../../../providers/przeliczenia.js";
import { useLayoutEffect, useState } from 'react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  plugins
);

//const svg = '<svg viewBox="64 64 896 896" focusable="false" data-icon="picture" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-40 632H136v-39.9l138.5-164.3 150.1 178L658.1 489 888 761.6V792zm0-129.8L664.2 396.8c-3.2-3.8-9-3.8-12.2 0L424.6 666.4l-144-170.7c-3.2-3.8-9-3.8-12.2 0L136 652.7V232h752v430.2zM304 456a88 88 0 100-176 88 88 0 000 176zm0-116c15.5 0 28 12.5 28 28s-12.5 28-28 28-28-12.5-28-28 12.5-28 28-28z"></path></svg>';
const icon_img = new Image();
icon_img.src = "img/icon2.png";


const formatLabel = (format, unit) => (context) => {
  if (format) {
    return unit + " " + przeliczenia.milisecondsToTime(context.raw);
  }
  return context.raw + " " + context.dataset.label;
}

const ChartStyled = styled.div`
    height: ${(props) => 200 + props.rowNum * 30}px;
    opacity: ${(props) => props.opacity};
  `

function milisecondsToTime(mili) {
  const seconds = Math.round(mili / 1000);
  const min = Math.floor(seconds / 60);
  console.log("min", min);
  const hours = Math.floor(min / 60);
  console.log("hu", hours)
  return getTimePart(hours, "h : ") + getTimePart(min % 60, "min ") + " " + getTimePart(seconds % 60, "s", 1);
}

function getTimePart(num, ending, deviation = 0) {
  if (deviation && num + deviation !== 60) {
    num = num + deviation;
  }
  if (num) {
    if (num < 10) {
      return num + ending;
    }
    return ("0" + num).slice(-2) + ending;
  }
  return "";
}
console.log(milisecondsToTime(365718810517153));
function Chart({ data, prezentacjeNazwy, title = "Liczba wyświetleń wszytskich Prezentacji.", unit = "liczba wyświetleń", time, name, dirs}) {

  const [opacity, setOpacity] = useState(0);
  const [modalData, setModaldata] = useState();
  const handleOk = () => {
      setModaldata();
  };
  const handleCancel = () => {
      setModaldata();
  };
  const dataMap = new Map(data);
  const labels = Array.from(dataMap.keys());
  const records = Array.from(dataMap.values());

  const showPrev = (slide, prezSet)=>{
    console.log("prezSet", prezSet);
    const slideFile = slide + ".zip";
    console.log("dirs", dirs);
    const paths = dirs.find(dir=>dir[1].some(file=>file === slideFile));
    const prevUrl = window.location.protocol +"//node.skyrep.pl?slide=" + slide + "&dir=" + paths[0];
    console.log("prevUrl", prevUrl);
    const state = {slide, src: prevUrl};
    setModaldata(state);
}

  useLayoutEffect(()=>{
    console.log("useLayoutEffect");
    setOpacity(0);
  }, [labels.length]);
  console.log(dataMap);

  const options = {
    animation: {
      delay: 1000,
    },
    interaction: {
      mode: 'y',
    },
    indexAxis: 'y',
    responsive: true,
    onResize:()=>setOpacity(1),
    maintainAspectRatio: false,
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: title,
      },
      tooltip: {
        intersect: false,
        padding: 10,
        callbacks: {
          label: formatLabel(time, unit),
          labelTextColor: function (context) {
            return 'white';
          }
        }
      },
    }
  };
  const plugins = [];


  if(name === "slajdy"){
    console.log("slajdy yes");
    plugins.push({
      id: "addicons",
      afterDraw: (chart) => {
        var ctx = chart.ctx; 
        var xAxis = chart.scales['x'];
        var yAxis = chart.scales['y'];
        yAxis.ticks.forEach((value, index) => { 
          var y = yAxis.getPixelForTick(index);   
          ctx.drawImage(icon_img, xAxis.left - 30, y - 11);
        }); 
      }
  },{
    id: 'yAxisCustomClick',
    afterEvent: (chart, event) => {
      const evt = event.event;
      if (evt.type === 'click') {
        const basePixel = Object.values(chart.scales).filter(s => s.id === 'x')[0].getBasePixel();
        if(evt.x < basePixel && evt.x > basePixel - 30) {
        const labelIndex = Object.values(chart.scales).filter(s => s.id === 'y')[0].getValueForPixel(evt.y);
        const label = Object.values(chart.scales).filter(s => s.id === 'y')[0].getTicks()[labelIndex]?.label;
        if (label) {
          console.log("prezentacjeNazwy", prezentacjeNazwy);
          console.log("label", label);
          const dirname = prezentacjeNazwy.get(label);
          console.log("dirr", dirname)
          showPrev(label, dirname);
        }
      }
      }
    }
  });

  options.scales = {
    y: {
      ticks: {
        padding: 30,
      }
    }
  }
}

  if (time) {
    options.scales = {
      x: {
        ticks: {
          callback: (label) => time ? przeliczenia.milisecondsToTime(label) : label,
        },
      },
    }
  }

  const chartData = {
    labels,
    datasets: [
      {
        spanGaps: true,
        label: unit,
        data: records,
        backgroundColor: '#149288',
      },
    ],
  };
  return <ChartStyled opacity={opacity} rowNum={labels.length}><Bar plugins={plugins} options={options} data={chartData} ></Bar>
  <Modal width={1024} height={768} visible={modalData} title={modalData?.slide}  onOk={handleOk} onCancel={handleCancel}>
         <iframe title={modalData?.slide}  scrolling="no" width="100%" height="768px" frameBorder="0" marginHeight="0px" marginWidth="0px" src={modalData?.src}></iframe>
      </Modal>
  </ChartStyled>

}

export default Chart;