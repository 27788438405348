
import { DatePicker, Space, Spin, Tabs, Button, Modal, Switch } from 'antd';
import WeeklyStats from "./weeklyStats.js";
import style from './week.module.css';
import "./week.css";
import { useState, useRef, useEffect } from 'react';
import PrezEdit from './prezEdit.js';

const { TabPane } = Tabs;



function Week({ weekData, onWeekChange, chosenDate, loading, customPrez, onChangeCustomPrez }) {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [actionWidth, setActionWidth ] = useState({minWidth: "unset"});

    const calendarRef = useRef();


    const showModal = () => {
        setIsModalVisible(true)
    };

    const handleOk = () => {
        setIsModalVisible(false)
    };

    const handleCancel = () => {
        console.log("cancel");
        setIsModalVisible(false);
    };

    const dataPickerChangeEvent = (date) => {
        if (date) {
            onWeekChange(date);
        } else {
            onWeekChange();
        }
    }

    const onChange = (event) => {
        const active = event === "custom";
        onChangeCustomPrez({ active });
    }

    const onChosenSlides = (slides) => onChangeCustomPrez({ slides });
    const onChangeOrderFlag = (order) => onChangeCustomPrez({ order });

    useEffect(()=>{
        const actWidth = calendarRef.current?.offsetWidth  ? { minWidth: calendarRef.current.offsetWidth + "px" } : {minWidth:"unset"};
       setActionWidth(actWidth);
    },[])





    const calendar = <div ref={calendarRef} className={style.calendar}><span>Wybierz Tydzień</span><Space direction="vertical" size={18}><DatePicker disabled={loading} value={chosenDate} onChange={dataPickerChangeEvent} picker="week" bordered={false} /></Space></div>


    console.log("week render", customPrez, actionWidth);
    return <>
        <Tabs onChange={onChange} type="card" activeKey={customPrez.active ? "custom" : "all"} tabBarExtraContent={calendar}>
            <TabPane tab="wszystkie wizyty" key="all">
                <h3 style={{ paddingLeft: "1rem" }}>W statystykach uwzględnione sa wszytskie wizyty</h3>
            </TabPane>
            <TabPane tab="ustalona wizyta" key="custom">
                <div className={style.prezEdition}><div className={style.customPrez}><h3>Sprawdź ststystyki dla vizyty zawierającej slajdy:</h3>{customPrez.slides.join(", ")}</div>
                    <div className={style.actions} style={actionWidth}><Button disabled={loading} style={{ marginRight: "1rem" }} onClick={showModal}>edytuj</Button>
                        <div><Switch disabled={loading} className={style.switch} checked={customPrez.order} onChange={onChangeOrderFlag} />wymagana kolejność slajdów</div>
                    </div></div>
            </TabPane>
        </Tabs>
        {!loading ? weekData && <WeeklyStats weekData={weekData} chosenDate={chosenDate} /> : <Spin style={{ display: 'block', textAligin: 'left', margin: '2rem' }} size="large" />}
        <Modal title="Edycja vizyty" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={900} transitionName="">
            <PrezEdit customSlides={customPrez.slides} onChosenSlides={onChosenSlides} />
        </Modal>
    </>

}

export default Week;