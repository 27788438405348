import { przeliczenia } from "../../../providers/przeliczenia";
import * as moment from 'moment';

 function VisitDetails({ startV, endV, reducedVisitTime }) {
    const timeVisit = +endV[1] - startV[1];
    const allVisitTime = Math.max(reducedVisitTime, timeVisit)
    return <div className='details'>
        <p><strong>start wizyty:</strong> {moment(+startV[1]).format("DD MM YYYY hh:mm a")}</p>
        <p><strong>koniec wizyty: </strong>{moment(+endV[1]).format("DD MM YYYY hh:mm a")}</p>
        <p><strong>czas wizyty z limitem czasu aktywności:</strong> {przeliczenia.milisecondsToTime(reducedVisitTime, 0)}</p>
        <p><strong>całkowity czas wizyty:</strong> {przeliczenia.milisecondsToTime(allVisitTime, 0)}</p>
    </div>

}

export default VisitDetails;