
import React from "react";
import styled from '@emotion/styled'
import { persons, reactNav } from "../../providers/data.js";
import { withCustomHooks, customHooks } from '../../providers/warpHooks.js'
import { Spin } from 'antd';
import { przeliczenia } from "../../providers/przeliczenia.js";
import { httpClient } from "../../providers/httpClient.js";

const TableStyled = styled.table`
th, td {
    padding: 1rem 1.5rem;
  }
  th{
    background-color: black;
    color:white;
  }

      border-collapse: collapse;
      border-radius: 0.5rem;
      overflow: hidden;
      border: 2px solid white;
  
  tr:nth-child(even) {
    background-color:black;
    color:white;
}
tr:nth-child(even) button {
  color:black;
}
  tr{
    background-color: #eeeeee;
  }

  td > button {
      margin: 1rem;
      width: 100%;
      padding: 1rem 0;
  }
`;


class GroupsClass extends React.Component {

    navigate;
    subscribe;


    postURL(key, value, url) {
        var form = document.createElement("FORM");
        form.method = "POST";
        form.style.display = "none";
        document.body.appendChild(form);
        form.action = url;
        const input = document.createElement("INPUT");
        input.type = "hidden";
        input.name = decodeURIComponent(key);
        input.value = decodeURIComponent(value);
        form.appendChild(input);

        form.submit();
    }

    reactNavChange( group, num){
        this.navigate("./../stats/chart/" + num, {state:{group}});
    }

    showVizit = (group) => {
        console.log('index', group);
        reactNav ? this.reactNavChange(group, 5) :  this.postURL('groups', JSON.stringify(group), "statystykiB.php?w=5")
    }

    showTime = (group) => {
        console.log(group);
        reactNav ? this.reactNavChange(group, 6) : this.postURL('groups', JSON.stringify(group), "statystykiB.php?w=6")
    }

    constructor(props) {
        super();
        this.navigate = props.navigate;
        this.dataBaseName = props.dataBaseName;
        console.log('constructor')
        this.state = { syncDates: null};
      //  console.log('sss', style);
        //  addStyle.call(this,style)
    }

    componentDidMount() {
        this.subscribe = httpClient.getAllData(this.dataBaseName).subscribe((allData)=>{
            const personList = persons.reduce((a,b)=>{
                return [...a, ...b.members];
            },[])
            const syncDates = przeliczenia.getSyncTime(allData, personList);
            console.log("syncDates", syncDates);
            this.setState({...this.state, syncDates});
        });

    }

    
  componentWillUnmount() {
    this.subscribe.unsubscribe();
  }

    render() {
        console.log("update")
        return (
            <TableStyled>
                <thead>
                    <tr><th>nazwa grupy</th><th>przedstawiciele</th><th  style={{minWidth:"200px"}}>data synchronizacji</th><th>kierownik</th><th>statystyki</th></tr>
                </thead>
                <tbody>
                    {persons.map((group, index) => (
                        <tr key={"k" + index}><td>{group.name}</td>
                        <td>{group.members.map((member, num) => (<span key={"pp" + index + "k" + num}>{member}<br /></span>))}</td>
                        <td style={{textAlign:"center"}}>{this.state.syncDates ? group.members.map((member, num) => (<span key={"pr" + index + "k" + num}>{this.state.syncDates.get(member)}<br /></span>)) : <Spin />}</td>
                            
                            <td>{group.lider}</td><td><button onClick={this.showVizit.bind(this, group)}>liczba wizyt</button><button onClick={this.showTime.bind(this, group)}>czas wizyt</button></td></tr>
                    ))}
                </tbody>
            </TableStyled>
        );
    }


}

export const Groups = withCustomHooks(GroupsClass, customHooks.useNavigate, customHooks.useParams);